import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { createApp } from 'vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false }, { ease: 'ease', speed: 600 });

let app = createApp();
const router = createRouter({
  // history: createWebHistory(process.env.NODE_ENV === 'production' ? "qrpay" : "/"), // hash模式：createWebHashHistory，history模式：createWebHistory
  history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: '/',
      redirect: '/qrpay'
    },

    {
      path: '/qrpay',
      name: 'qrpay',
      component: () => import(/* webpackChunkName: "pay" */ '@/views/pay/qrpay.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/utrpayment',
      name: 'utrpayment',
      component: () => import(/* webpackChunkName: "pay" */ '@/views/pay/utrpayment.vue'),
      meta: {
        index: 1
      }
    },

    {
      path: '/checkout',
      name: 'checkout',
      component: () => import(/* webpackChunkName: "pay" */ '@/views/pay/checkout.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/expired',
      name: 'expired',
      component: () => import(/* webpackChunkName: "pay" */ '@/views/pay/expired.vue'),
      meta: {
        index: 1
      }
    },
  ]
})


router.beforeEach((to, from, next) => {
  // console.log("to===",to)
  NProgress.start();
  if (!['/expired'].includes(to.path) && !to.query.id) {
    next('/expired')
    return
  }
  next();
  NProgress.done();
});

router.afterEach((to, from) => {

});

// router.beforeEach((to, from, next) => {
//   NProgress.start();
//   if (to.query.id) {
//     sessionStorage.setItem('hiddenId', to.query.id);
//   }
//   if (!['/expired'].includes(to.path) && to.query.id) {
//     // 隐藏id参数，但仍然在后台携带
//     const pathWithoutId = to.path;

//     if (['/checkout'].includes(to.path)) {
//       next();
//     }
//     else {
//       next({ path: pathWithoutId, query: {} });
//     }

//   } else {
//     if (!to.name) {
//       next('/expired')
//     } else {
//       next();
//     }
//     // console.log("to===", to)
//   }
// });

// router.afterEach((to, from) => {
//   NProgress.done();
// });


export default router
